import React, { lazy, Suspense, useEffect } from 'react'; // Import useEffect from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Login from './Pages/Login';
import { PrivateRoute } from './components/PrivateRoute/PrivateRoute';
import Subscribe from './Pages/Subscribe';
import SelectTeams from './Pages/SelectTeams';
import TeamAccept from './Pages/Team_Accept';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Layout = lazy(() => import('./components/Common/Layout'));

function App() {
  return (
    <div className="App font-system">
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <BrowserRouter>
        <Switch>
          <Route path="/select_teams" component={SelectTeams} exact={true} />
          <Route path="/login" component={Login} exact={true} />
          <Route path="/subscribe" component={Subscribe} exact={true} />
          <PrivateRoute path="/team_accept/:invite_id" component={TeamAccept} exact={true} />
          <Suspense fallback={<div>Loading...</div>}>
            <PrivateRoute component={Layout} />
          </Suspense>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
