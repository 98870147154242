import { toast } from 'react-toastify';
import { Button, MenuItem, Select } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Logo from '../assets/img/logo.png';
import AuthApi from '../api/authApi';
import { SuccessResponse } from '../models/system/successResponse';
import { handleAxiosError } from '../utils/commonUtils';
import ITeam from '../models/platform/team';


export default function SelectTeams() {
  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const userId: any = searchParams.get('user_id');
  const team_accept: any = searchParams.get('team_accept');

  const [userTeams, setUserTeams] = useState<ITeam[]>([]);
  const [selectTeam, setSelectTeam] = useState('');

  const currentURL = window.location.href;
  const url = new URL(currentURL);
  const callbackUrl = `${url.protocol}//${url.host}`;


  const handleChangeTeams = (event: any) => {
    try {
      const selectedTeamId = event.target.value;
      setSelectTeam(selectedTeamId);
    }
    catch (error: any) {
      toast.error(handleAxiosError(error).message);
    }
  };

  const handleSubmit = async () => {
    try {
      await selectTeamApi();
    }
    catch (error: any) {
      toast.error(handleAxiosError(error).message);
    }
  };


  const getUserTeams = async () => {
    try {
      const response = await AuthApi.getUserTeams(userId);
      if (response.success) {
        const data = (response as SuccessResponse).data;

        setUserTeams(data);
        setSelectTeam(data[0]._id);
      }
      else {
        toast.error(response.message);
      }
    }
    catch (error: any) {
      toast.error(handleAxiosError(error).message);
    }
  };

  const selectTeamApi = async () => {
    try {
      const response = await AuthApi.selectTeam(selectTeam, userId, callbackUrl, team_accept);
      if (response.success) {
        const data = (response as SuccessResponse).data;

        window.location.href = data.redirect_url;
      }
      else {
        toast.error(response.message);
      }
    }
    catch (error: any) {
      toast.error(handleAxiosError(error).message);
    }
  };


  useEffect(() => {
    try {
      getUserTeams();
    }
    catch (error: any) {
      toast.error(handleAxiosError(error).message);
    }
  }, []);

  return (
    <>
      <div className="min-h-screen bg-white flex">
        <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-20 xl:px-24 bg-login">
          <div className="mx-auto w-max bg-white p-8 rounded-md shadow-md">
            <div>
              <div className="flex items-center justify-center flex-shrink-0 px-4 text-black font-bold text-3xl pt-4 mb-6">
                <img src={Logo} alt={'{ everythingData :}'} />
              </div>
              <h1 className="flex items-center justify-center">
                {' '}
                Your are a member of multiple Teams.
              </h1>
              <h1 className="flex items-center justify-center">
                Now choose your team.
              </h1>
            </div>
            <div className="mt-8">
              <div>
                <div>
                  <div
                    className=""
                    style={{ display: 'flex', justifyContent: 'center' }}
                  >
                    <Select
                      id="demo-simple-select-filled"
                      value={selectTeam}
                      onChange={handleChangeTeams}
                      style={{ width: '100%' }}
                    >
                      {userTeams.map((team) => (
                        <MenuItem key={team._id} value={team._id}>
                          {team.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex justify-center pt-4 text-sm text-sendory-text text-opacity-80">
              <Button
                style={{
                  backgroundColor: '#4285F4',
                  color: 'white',
                  padding: '10px 20px',
                  borderRadius: '5px'
                }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>

      {/* ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
    </>
  );
}
