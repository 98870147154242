import axios, { AxiosResponse } from 'axios';
import authHeader from '../utils/authHeaderUtils';
import { handleAxiosError } from '../utils/commonUtils';
import { SuccessResponse } from '../models/system/successResponse';


const API_URL = process.env.REACT_APP_BASE_URL;

class TeamsApi {

    async getTeam() {
        try {
            const response = await axios.get(`${API_URL}/teams/me`, {
                headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

    async getUsers() {
        try {
            const response = await axios.get(`${API_URL}/teams/me/users`, {
                headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

    async createInvites(inviteData: any) {
        try {
            const response = await axios.post(`${API_URL}/teams/me/invites`, inviteData, {
                headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

    async generateApiKey() {
        try {
            const response = await axios.put(`${API_URL}/teams/me/api-key/create`, {}, {
                headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

    async removeApiKey() {
        try {

            console.log(`${API_URL}/teams/me/api-key/remove`)
            console.log(authHeader())

            const response = await axios.put(`${API_URL}/teams/me/api-key/remove`, {}, {
                headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

    async updateTeamName(team_name: string) {
        try {
            const response = await axios.put(`${API_URL}/teams/me/team_name/${team_name}`, {}, {
                headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

    async getAllDataSourceConnections() {
        try {
            const response = await axios.get(`${API_URL}/teams/me/data-sources/connections`, {
                headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

    async getInvoices(page: any, rowsPerPage: any) {
        try {
            const response = await axios.get(`${API_URL}/teams/me/invoices?page=${page}&rowsPerPage=${rowsPerPage}`, {
                headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

    async getSubscription() {
        try {
            const response = await axios.get(`${API_URL}/teams/me/licenses/subscriptions`, {
                headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

    async getTrialLicenses() {
        try {
            const response = await axios.get(`${API_URL}/teams/me/licenses/trial-licenses`, {
                headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

    async acceptInvite(invite_id: string) {
        try {
            const payload = {
                invite_id,
            };

            const response = await axios.post(`${API_URL}/teams/me/invites/accept`, payload, {
                headers: authHeader(),
            });

            return response.data as SuccessResponse;
        }
        catch (error) {
            return handleAxiosError(error);
        }
    }

}

export default new TeamsApi();
