import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { handleAxiosError } from '../utils/commonUtils';
import '../css/Team_Accept.css';
import Logo from '../assets/img/logo.png';
import alertImage from '../assets/img/alert.png'; // Import the alert image
import checkImage from '../assets/img/check.png'; // Import the alert image
import TeamsApi from '../api/teamsApi';


interface RouteParams {
  invite_id: string;
}

const TeamAccept: React.FC = () => {
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<boolean>(false);
  const { invite_id } = useParams<RouteParams>();

  const acceptInvite = async (invite_id: string) => {
    try {
      const response = await TeamsApi.acceptInvite(invite_id);
      if (response.success) {
        setMessage(response.message);
        setError(false);
      }
      else {
        setError(true);
        setMessage(response.message);
      }
    }
    catch (error: any) {
      toast.error(handleAxiosError(error).message);
    }
  };

  
  useEffect(() => {
    try {
      const checkInvite = async () => {

        await acceptInvite(invite_id);

        setTimeout(() => {
          document.querySelector('.content-container')?.classList.add('show');
        }, 500);
      };

      checkInvite();
    }
    catch (error: any) {
      toast.error(handleAxiosError(error).message);
    }
  }, []);


  return (
    <div className="page-container">
      <div className="logo">
        <img src={Logo} alt="everythingData" />
      </div>
      <div className={`content-container ${error ? 'error' : 'success'}`}>
        <div className="message-container">
          <img
            src={error ? alertImage : checkImage}
            alt={error ? 'Alert' : 'Check'}
            className="status-image"
          />
          <p className={`message ${error ? 'error-message' : 'success-message'}`}>{message}</p>
        </div>
      </div>
      <Link to="/dashboard" className="button">
        Return to Dashboard
      </Link>
    </div>
  );
};

export default TeamAccept;
