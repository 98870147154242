
import { DateTime } from 'luxon';
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import { Route, Redirect, RouteProps } from 'react-router-dom'; // Import RouteProps
import { getCookie, removeCookie } from '../../utils/cookiesUtils';
import { decodeJWT } from '../../utils/jwtUtil';
import { handleAxiosError } from '../../utils/commonUtils';
import { checkSessionExpiration } from '../../utils/sessionUtils';


export const PrivateRoute = ({ component: Component = null, render: Render = null, ...rest }: any) => {
  const [isTokenValidated, setIsTokenValidated] = useState<null | boolean>(null);
  const [isLoading, setIsLoading] = useState(true);

  const token = getCookie('dashboard_token');


  useEffect(() => {
    try {
    setIsTokenValidated(null);

    if (token) {
      setIsTokenValidated(true);

      try {
        const jwt_decoded = decodeJWT(token);

        if (jwt_decoded) {
          const currentTimestamp = Math.floor(DateTime.now().toMillis() / 1000);
          const expirationTimestamp = jwt_decoded.exp || 0;

          if (expirationTimestamp > currentTimestamp) {

            // setAuth(true);
          }
          else {

            setIsTokenValidated(false);
            removeCookie('dashboard_token');

            // setAuth(false);
            // removeCookie('dashboard_token');
          }
        }
      }
      catch (error) {
        setIsTokenValidated(false);
        // setAuth(false);
        removeCookie('dashboard_token');
      }
    }
    else {
      setIsTokenValidated(false);
    }

    setIsLoading(false);
  }
  catch (error: any) {
    toast.error(handleAxiosError(error).message);
  }
  }, []);


  useEffect(() => {
    // Add the session expiration check using checkSessionExpiration
    const sessionExpirationInterval = checkSessionExpiration();

    // Clear the interval when the component unmounts
    return () => {
      clearInterval(sessionExpirationInterval);
    };
  }, []);
  
  
  if (isLoading) {
    return null;
  }
  else {
    // if (token && isTokenValidated && auth) {
    if (token && isTokenValidated) {
      return (
        <Route
          {...rest}
          render={(props) => (Render ? Render(props) : <Component {...props} />)}
        />
      );
    }
    else {
      window.location.href = window.location.origin + '/login?callback_url=' + window.location.href;
    }
  }


  return <></>;
}

