import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Redirect } from 'react-router-dom';
import qs from 'qs';
import Logo from '../assets/img/logo.png';
import AuthApi from '../api/authApi';
import { DASHBOARD_COOKIE_EXPIRE_TIME, REACT_APP_ENABLE_FB_TESTING } from '../config/system/systemConfig';
import { getCookie, setCookie } from '../utils/cookiesUtils';
import { SuccessResponse } from '../models/system/successResponse';
import { handleAxiosError } from '../utils/commonUtils';
import { url } from 'inspector';
import { decodeJWT } from '../utils/jwtUtil';


export default function Login(props: any) {
  const [login, setlogin] = useState(false);
  const [loginUrl, setLoginUrl] = useState('');
  const [callBack, setCallback] = useState('');

  let [testToken, setTestToken] = useState('');

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const checkLogin = () => {
    try {
      let { token, callback_url, state } = qs.parse(props.location.search, {
        ignoreQueryPrefix: true,
      }) as {
        token: string;
        callback_url: string;
        state: string;
      };

      const urlParams = new URLSearchParams(window.location.search);
      const team_accept = urlParams.get('state');

      if (team_accept)
        setCookie('team_invite', team_accept, DASHBOARD_COOKIE_EXPIRE_TIME);

      if (token) {
        setCookie('dashboard_token', token, DASHBOARD_COOKIE_EXPIRE_TIME);
      }

      if (callback_url) {
        const callback_url_obj = new URL(callback_url);

        if (team_accept) {
          window.location.href = team_accept
          // setCallback(state);
        }
        else
          setCallback(callback_url_obj.pathname);
      }

      let authorization = getCookie('dashboard_token');

      if (authorization) {
        setlogin(true);
      }
      else {
        setlogin(false);
        setUrl();
      }
    }
    catch (error) {
      toast.error(handleAxiosError(error).message);
    }
  };

  const setUrl = async () => {
    try {
      const redirect_uri = window.location.href;

      await getGoogleLoginUrl(redirect_uri);
    }
    catch (error) {
      toast.error(handleAxiosError(error).message);
    }
  };


  const getGoogleLoginUrl = async (redirect_uri: string) => {
    try {
      const response = await AuthApi.getGoogleLoginUrl(redirect_uri)
      if (response.success) {
        const data = (response as SuccessResponse).data;

        setLoginUrl(data.login_url);
      }
      else {
        toast.error(response.message);
      }
    }
    catch (error: any) {
      toast.error(handleAxiosError(error).message);
    }
  };


  // TESTING FOR FACEBOOK
  const handleTestLogin = () => {
    try {
      let json_token = decodeJWT(testToken);

      if (json_token?.user_id == '621a2f85040174000aaf58b7' && json_token?.team_id === '621a2f85040174000aaf58b9') {
        setCookie('dashboard_token', testToken, DASHBOARD_COOKIE_EXPIRE_TIME);

        let authorization = getCookie('dashboard_token');

        if (authorization) {
          setlogin(true);
        }
        else {
          setlogin(false);
          setUrl();
        }
      }
      else {
        toast.error("INCORRECT PASSCODE");
      }
    }
    catch (error) {
      toast.error("Error decoding token");
    }

  };



  useEffect(() => {
    try {
      checkLogin();
    }
    catch (error) {
      toast.error(handleAxiosError(error).message);
    }
  }, [props]);


  return (
    <>
      {login ? (
        <Redirect to={callBack ? callBack : '/'} />
      ) : (
        <div className="min-h-screen bg-white flex">
          <div className="flex-1 flex flex-col justify-center py-12 px-4 sm:px-6 lg:px-20 xl:px-24 bg-login">
            <div className="mx-auto w-max bg-white p-8 rounded-md shadow-md">
              <div>
                <div className="flex items-center justify-center flex-shrink-0 px-4 text-black font-bold text-3xl pt-4">
                  <img src={Logo} alt={'{ everythingData :}'} />
                </div>
                {/* <h2 className="mt-6 text-2xl font-extrabold text-gray-900">
                  Sign in to your account
                </h2> */}
              </div>

              <div className="mt-16">
                <div>
                  <div>
                    <div className="">
                      <button
                        className={
                          loginUrl
                            ? 'flex w-full justify-center bg-google py-1 px-1 hover:shadow-inner'
                            : 'flex w-full justify-center bg-gray-500 py-1 px-1 cursor-not-allowed'
                        }
                        type="button"
                        onClick={() => {
                          window.location.href = loginUrl;
                        }}
                        disabled={loginUrl ? false : true}
                      >
                        <div>
                          <svg width="40" height="40">
                            <g
                              id="Google-Button"
                              stroke="none"
                              strokeWidth="1"
                              fill="none"
                              fillRule="evenodd"
                            >
                              <rect
                                x="0"
                                y="0"
                                width="40"
                                height="40"
                                rx="2"
                                style={{ fill: 'rgb(255, 255, 255)' }}
                              ></rect>
                              <g
                                id="logo_googleg_48dp"
                                transform="translate(10.5, 10.5) scale(1.1)"
                              >
                                <path
                                  d="M17.64,9.20454545 C17.64,8.56636364 17.5827273,7.95272727  17.4763636,7.36363636 L9,7.36363636 L9,10.845 L13.8436364,10.845 C13.635,11.97 13.0009091,12.9231818 12.0477273,13.5613636 L12.0477273,15.8195455 L14.9563636,15.8195455 C16.6581818,14.2527273 17.64,11.9454545 17.64,9.20454545 L17.64,9.20454545 Z"
                                  id="Shape"
                                  fill="#4285F4"
                                ></path>
                                <path
                                  d="M9,18 C11.43,18 13.4672727,17.1940909 14.9563636,15.8195455 L12.0477273,13.5613636 C11.2418182,14.1013636 10.2109091,14.4204545 9,14.4204545 C6.65590909,14.4204545 4.67181818,12.8372727  3.96409091,10.71 L0.957272727,10.71 L0.957272727,13.0418182  C2.43818182,15.9831818 5.48181818,18 9,18 L9,18 Z"
                                  id="Shape"
                                  fill="#34A853"
                                ></path>
                                <path
                                  d="M3.96409091,10.71 C3.78409091,10.17 3.68181818,9.59318182 3.68181818,9  C3.68181818,8.40681818 3.78409091,7.83 3.96409091,7.29 L3.96409091,4.95818182  L0.957272727,4.95818182 C0.347727273,6.17318182 0,7.54772727 0,9 C0,10.4522727  0.347727273,11.8268182 0.957272727,13.0418182 L3.96409091,10.71 L3.96409091,10.71 Z"
                                  id="Shape"
                                  fill="#FBBC05"
                                ></path>
                                <path
                                  d="M9,3.57954545 C10.3213636,3.57954545 11.5077273,4.03363636 12.4404545,4.92545455  L15.0218182,2.34409091 C13.4631818,0.891818182 11.4259091,0 9,0 C5.48181818,0  2.43818182,2.01681818 0.957272727,4.95818182 L3.96409091,7.29 C4.67181818,5.16272727  6.65590909,3.57954545 9,3.57954545 L9,3.57954545 Z"
                                  id="Shape"
                                  fill="#EA4335"
                                ></path>
                                <path
                                  d="M0,0 L18,0 L18,18 L0,18 L0,0 Z"
                                  id="Shape"
                                ></path>
                              </g>
                            </g>
                          </svg>
                        </div>
                        <div className="align-middle w-full text-white font-bold font-sans">
                          <span className="inline-block align-middle mt-2">
                            Login with Google
                          </span>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-center pt-4 text-sm text-sendory-text text-opacity-80">
                Sign in or Create an account
              </div>

              {/* TESTING FOR FACEBOOK */}
              {REACT_APP_ENABLE_FB_TESTING == 'true' && (
                <div className="mt-8">
                  <input
                    type="text"
                    value={testToken}
                    onChange={(e) => setTestToken(e.target.value)}
                    placeholder="Enter FB testing passcode:"
                    className="px-4 py-2 border rounded-md text-gray-700 w-full"
                  />
                  <button
                    onClick={handleTestLogin}
                    className="mt-4 bg-blue-500 text-white px-6 py-2 rounded-md w-full hover:bg-blue-600"
                  >
                    Test Login
                  </button>
                </div>
              )}


            </div>
          </div>
        </div>
      )}
    </>
  );
}
